@font-face {
    font-family: '';
    src: url('../../public/runescape_uf.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
backdrop-filter: blur(10px);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.3) inset;
    padding: 0 50px;
    opacity: 1;
    border: 1px solid lightgray;
    
  }
  
  .navbar__logo h1 {
    flex: 1; /* take up all available horizontal space */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: #fff;
    font-family: 'Impact', sans-serif;
  }

  
  
  .navbar__list {
    display: flex;
    list-style: none;
  }
  
  .navbar__item {
    margin-left: 20px;
  }
  
  .navbar__item a {
    color: #fff;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: bold;
    align-self: 'center'
    
  }
  
  .navbar__contact-button {
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.6rem;
    
    font-family: 'Impact', sans-serif;
    font-weight: bold;
    
    transition: all 0.2s ease-in-out;
    }
    
    .navbar__contact-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
    }
    


    .navbar__launchApp {
        background-color: rgba(255, 255, 255, 0.5);
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: 'Impact', sans-serif;
        transition: all 0.2s ease-in-out;
        }
        
        .navbar__launchApp:hover {
            transform: scale(0.9);
        background-color: rgba(255, 255, 255, 0.5);
        }
        .social-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white
         
          }
          
          .social-icon {
            margin: 10px;
            font-size: 20px;
            
            transition: all 0.2s ease-in-out;
          }
          
          .social-icon:hover {
            transform: scale(1.2);
          }
          

    .socialbar{
        background-color: rgba(10, 40, 40, 0.7);
        position:sticky;
        top: 0;
        margin: 0;
        z-index: 9999;
        

    }
    
    .social-icon a {
        color: white;
      }


      .navbar__logoBtn {
        background-color: transparent;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 3rem;
        fontFamily: 'Alegreya-Regular';
        font-weight: bold;
        transition: all 0.2s ease-in-out;
        position: absolute;
      
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -2;
        }
        
        .footer-button {
            background-color: transparent;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 1.4rem;
            ffontFamily: 'Alegreya-Regular';
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            }
            
            .footer-button:hover {
            color: rgba(255, 255, 255, 0.5);
            }

            .footer__list {
                display: flex;
                list-style: none;
                justify-content: flex-start; /* Align items to the left */
                margin-left: -80px
              }
              
              .footer__item {
                margin-left: 20px;
              }