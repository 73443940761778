/* Home.css */

@font-face {
    font-family: 'RuneScape';
    src: url('../../public/RuneScape-Chat-07.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    /* Add more src lines for additional font formats (e.g., woff, ttf, eot) */
  }



  @font-face {
    font-family: 'Times';
    src: url('../../public/times.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    /* Add more src lines for additional font formats (e.g., woff, ttf, eot) */
  }


h1 {
    font-size: 6vh;
    font-family:'RuneScape';
    color: #FFFF00;
}

h2 {
    font-size: 4vh;
    font-family:'RuneScape';
    color: #FFFF00;
}

h3 {
    font-size: 3vh;
    font-family:'RuneScape';
    color: #FFFF00;
}


p {
    font-size: 2.8vh;
    font-family:'RuneScape';
    color: white;

}

@media (max-width: 600px) {
    h1 {
        font-size: 4vh;
    }
    
    h2 {
        font-size: 3vh;
    }
    
    h3 {
        font-size: 6vw;
    }
    
    p {
        font-size: 4vw;
    }
}