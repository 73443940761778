.preloading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    opacity: 1; /* Initially fully visible */
  transition: opacity 0.2s ease-out; /* Add transition effect */
  }
  
  .loading-spinner {
    font-size: 2em;
    /* You can add a loading animation here if desired */
  }
  
  .main-content {
    text-align: center;
  }
  
  .main-content h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .main-content p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
  }
  
  .main-content button {
    background-color: #007bff;
    color: #fff;
    font-size: 1em;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

  .loading-spinner {
    font-size: 2em;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #007bff; /* This color is an example, you can change it */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .rotating-image {
    animation: spin 2s linear infinite; /* Adjust duration and timing function as needed */
    width:40%
   
  }
  

  
  
